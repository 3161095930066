import './App.css';
import { useEffect } from "react";
import Loading from './components/Loading';
import Header from './components/Header/index.jsx';
import Slider from './components/Slider/index.jsx';
import Rotate3D from './components/Rotate3D/index.jsx';
import Catalyst from './components/Catalyst/index.jsx';
import ShortForm from './components/ShortForm/index.jsx';
import Footer from './components/Footer/index.jsx';



function App() {
  useEffect(() => {
    (async () => {
      const LocomotiveScroll = (await import("locomotive-scroll")).default;
      const locomotiveScroll = new LocomotiveScroll();
    })();
  }, []);

  return (
    <div>
       <Loading/>
       <Slider/>
       <Header/>
       <Rotate3D/>
       <Catalyst/>
       <ShortForm/>
       <Footer/>
    </div>
  );
}

export default App;
