import React from "react";
import ScrambleText from "../ScrambleText";
import "./style.css";

export default function Footer() {
  return (
    <div
      className="FooterSec"
      id="contactSec"
    >

       <h1>
          <ScrambleText shuffle delay={0}>
          CONTACT@KSHIFT.LA
          </ScrambleText>
      </h1>
     

    </div>
  );
}
