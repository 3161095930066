import React, { useRef, useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import "./style.css";

// const CHARS = "!@# akscbja ckjasbcka apvmwef wpd mkac a,s csac $%^&* ():{} ;, .<>?";
const CHARS = "okjbfdsmERTYUS!#$%^⅋6SWOS˥ΛNpʞSWʞqʞSſ";

const ScrambleHover = ({ children, delay, shuffle = false }) => {
  const intervalRef = useRef(null);
  const CYCLES_PER_LETTER = shuffle ? 50 : 1;
  const SHUFFLE_TIME = shuffle ? 50 : 0;
  const [text, setText] = useState(children);


  const scramble = () => {
    let pos = 0;
    setTimeout(() => {
      stopScramble();
    }, 500);

    intervalRef.current = setInterval(() => {
      const scrambled = children
        .split("")
        .map((char, index) => {
          if (
            pos / CYCLES_PER_LETTER > index ||
            char === " " ||
            char === "." ||
            char === "," ||
            char === `'` ||
            char === `-` ||
            char === "!"
          ) {
            return char;
          }

          const randomCharIndex = Math.floor(Math.random() * CHARS.length);
          const randomChar = CHARS[randomCharIndex];

          return randomChar;
        })
        .join("");

      setText(scrambled);
      pos = shuffle ? pos + 1 : pos + 4;

      if (pos >= children.length * CYCLES_PER_LETTER) {
        stopScramble();
      }
    }, SHUFFLE_TIME);
  };

  const stopScramble = () => {
    clearInterval(intervalRef.current);
    setText(children);
  };


  return (
    <motion.span className="scrambleHover">
      <span>{children}</span>
      <motion.span
         onMouseEnter={scramble}
         onMouseLeave={stopScramble}
        className="scrambleHover--overlay"
      >
        {text.split("").map((char, index) => (
          <motion.span key={index}>
            {char}
          </motion.span>
        ))}
      </motion.span>
    </motion.span>
  );
};

export default ScrambleHover;
