import React from "react";
import "./style.css";
import TextAnim from "../TextAnim";

import img1 from "../../assets/img1.png";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";

import { useInView, motion } from "framer-motion";
import { useRef } from "react";

export default function Catalyst() {
  const data = [img1, img2, img3];
  const cont = useRef(null);
  const isInView = useInView(cont, {once: true, margin: "0%"})
  const cardWrapperVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration:1,
        delayChildren:0.2,
        staggerChildren: 0.5, // Adjust the stagger duration as needed
      },
    },
  };


  return (
    <section className="CatalystSec">
      <TextAnim>
        <h2>
        In short, we make stuff that will tell your story, increase your presence, generate new leads, and drive up conversations. 
        </h2>
      </TextAnim>
      <TextAnim>
        <h2>A catalyst to your success.</h2>
      </TextAnim>
      <motion.div 
      ref={cont}
      variants={cardWrapperVariant}
      initial="hidden"
      animate={isInView ? "visible" : ""}
      className="catalystWrapper">
        {data.map((src, index) => {
          return (
            <motion.div key={index}
            variants={{
              hidden: { 
                  opacity: 0,
                  x:`-${50*(index+1)}%` 
              },
              visible: {
                  opacity: 1,
                  x:"0%" ,
              },
            }}
            className="catalystCard">
              <img src={src} alt="image" />
            </motion.div>
          );
        })}
          <h3>case study 02: complete rebranding</h3>
      </motion.div>
    </section>
  );
}
