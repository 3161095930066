import React from "react";
import "./style.css";
import TextAnim from "../TextAnim";
import videoTemp from "../../assets/video.mp4";
import { useInView, motion } from "framer-motion";
import { useRef } from "react";

export default function ShortForm() {
  const data = [
    "/video/Vertical 1.mp4",
    "/video/Vertical 2.m4v",
    "/video/Vertical 3.m4v",];
  const cont = useRef(null);
  const isInView = useInView(cont, { once: true, margin: "0%" });
  const cardWrapperVariant = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.3,
        staggerChildren: 0.2, // Adjust the stagger duration as needed
      },
    },
  };

  return (
    <section className="ShortFormSec">
      <TextAnim>
        <h2>Short form video content on social media is inescapable.</h2>
      </TextAnim>
    
      <TextAnim>
        <h2>
        Take advantage of this and stand out from a sea of amateur content with{" "}<span className="logosmall">K-SHIFT</span>
        </h2>
      </TextAnim>
      <TextAnim>
        <h2>We make your brand cinematic.</h2>
      </TextAnim>
      <motion.div
        ref={cont}
        variants={cardWrapperVariant}
        initial="hidden"
        animate={isInView ? "visible" : ""}
        className="ShortFormWrapper"
      >
        {data.map((src, index) => {
          return (
            <motion.div
              key={index}
              variants={{
                hidden: {
                  opacity: 0,
                  x: "-10%",
                },
                visible: {
                  opacity: 1,
                  x: "0%",
                  transition:{
                    duration:1
                  }
                },
              }}
              className="ShortFormCard"
            >
              <video src={src} autoPlay loop="loop" muted></video>
            </motion.div>
          );
        })}
        <h3>case study 03: short form content</h3>
      </motion.div>
    </section>
  );
}
