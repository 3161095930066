import React from 'react'

import { useInView, motion } from "framer-motion";
import { useRef } from "react";


export default function TextAnim({delay = 0, children}) {
    const cont = useRef(null);

    const isInView = useInView(cont, {once: true, margin: "0%"})

 const textVariant = {
        hidden: { 
            opacity: 0,
            y:"100%" 
        },
        visible: {
            opacity: 1,
            y:"0%" ,
            transition:{
                delay : delay,
                duration:1,
            }
        },
      };

  return (
    <motion.div
    ref={cont}
    variants={textVariant}
    initial="hidden"
    animate={isInView ? "visible" : ""}
    transition={{ duration: 1, delay: 3.1 }}
    >
        {children}
    </motion.div>
  )
}
