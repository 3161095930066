import React, { useState, useEffect } from "react";
import "./style.css";

const Slider = () => {
  const [temperature, setTemperature] = useState(6300);
  const temperatureToRgb = {
    9900: { r: 205, g: 220, b: 255 },
    9500: { r: 208, g: 222, b: 255 },
    9100: { r: 212, g: 225, b: 255 },
    8700: { r: 217, g: 227, b: 255 },
    8300: { r: 222, g: 230, b: 255 },
    7900: { r: 228, g: 234, b: 255 },
    7500: { r: 235, g: 238, b: 255 },
    7100: { r: 243, g: 242, b: 255 },
    6700: { r: 252, g: 247, b: 255 },
    6300: { r: 255, g: 246, b: 247 },
    5900: { r: 255, g: 242, b: 236 },
    5500: { r: 248, g: 255, b: 183 },
    5100: { r: 255, g: 248, b: 167 },
    4700: { r: 255, g: 234, b: 144 },
    4300: { r: 255, g: 218, b: 122 },
    3900: { r: 255, g: 201, b: 100 },
    3500: { r: 255, g: 182, b: 78 },
    3100: { r: 255, g: 162, b: 57 },
    2700: { r: 255, g: 139, b: 39 },
    2300: { r: 255, g: 115, b: 23 },
    1900: { r: 255, g: 89, b: 11 },
    1500: { r: 255, g: 61, b: 4 },
  };

  const handleSliderChange = (event) => {
    const newTemperature = parseInt(event.target.value, 10);
    const temperatures = Object.keys(temperatureToRgb).map(Number);
    // Snap to specific values
    console.log(temperatures);
    const snappedTemperature = snapToValues(newTemperature, temperatures);
    setTemperature(snappedTemperature);
  };

  const snapToValues = (value, snapValues) => {
    const step = snapValues.reduce((acc, curr) =>
      Math.abs(value - curr) < Math.abs(value - acc) ? curr : acc
    );
    return step;
  };

  const calculateBackgroundColor = () => {
    const rgb = temperatureToRgb[temperature];
    if (rgb) {
      return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
    } else {
      return "rgb(255, 255, 255)"; // Default to white for unknown temperatures
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = calculateBackgroundColor();
  }, [temperature]);

  return (
    <div className="SliderColor">
      <input
        type="range"
        min="1500"
        max="9900"
        step="400"
        value={temperature}
        onChange={handleSliderChange}
        orient="vertical"
      />
      <small className="Value">
        {temperature}K
      </small>
    </div>
  );
};

export default Slider;
