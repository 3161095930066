import React, { useEffect, useState } from 'react'
import "./style.css";
import HideText from "../HideText";
import { textVariant } from "../../constants/variants";
import TextAnim from '../TextAnim';

import { Application } from '@splinetool/runtime';

export default function Rotate3D() {

  useEffect(() => {
    const canvas = document.getElementById('canvas3d');
    const canvas2 = document.getElementById('canvas3d2');


    const app2 = new Application(canvas2);
    app2.load('https://prod.spline.design/XwEHiVghXdinPLQq/scene.splinecode');


  }, []);

  return (
    <div className='Rotate3D' id='caseStudies'>
      <TextAnim>
        <h2>
        We materialize concepts into tangible outcomes.
        </h2>
      </TextAnim>

        <div className='Model'>
          <div> <canvas id="canvas3d" /> </div>
          <div><canvas id="canvas3d2" />
          <h3>(nfc equipped) <br />case study 01: business cards</h3>
          </div>
        </div>
    </div>
  )
}
