import React from "react";
import ScrambleText from "../ScrambleText";
import "./style.css";
import { motion, useAnimation } from "framer-motion";

export default function Loading() {
  const splashVariant = {
    hidden: { y: 0 },
    visible: {
      y: "-100%",
      transition: {
          ease:[0.11, 0, 0.5, 0],
        delay:2.4,
        duration: 0.6,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={splashVariant}
      className="LoadingSec"
    >

       <h1>
          <ScrambleText shuffle delay={0}>
            k-shift
          </ScrambleText>
      </h1>
     

    </motion.div>
  );
}
