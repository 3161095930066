import React from "react";
import { useRef, useEffect } from "react";
import { motion, useTransform, useScroll } from "framer-motion";
import { textVariant } from "../../constants/variants";

import "./style.css";
import ScrambleHover from "../ScrambleHover";
import TextAnim from "../TextAnim";

export default function Header() {
  const container = useRef(null);

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const sm = useTransform(scrollYProgress, [0, 1], [0, 100]);
  const mm = useTransform(scrollYProgress, [0, 1], [0, 150]);

  const opVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: 3.6,
      },
    },
  };

  return (
    <div className="HeaderSec" ref={container} id="HeaderSec">
      <nav>
        <a href="#caseStudies">
          <ScrambleHover shuffle delay={0}>
            case studies
          </ScrambleHover>
        </a>
        <a href="#contactSec">
          <ScrambleHover shuffle delay={0}>
            contact
          </ScrambleHover>
        </a>
        <a href="#HeaderSec">
          <ScrambleHover shuffle delay={0}>
            home
          </ScrambleHover>
        </a>
      </nav>
      <TextAnim delay={3.1}>
      <div 
      className="heading">
        <h1 >
          k-shift <span>serves as a digital content creation agency engine.</span>
        </h1>
      </div>
      </TextAnim>

      <motion.div
        className="HeaderVideo"
        initial="hidden"
        animate="visible"
        variants={opVariants}
      >
        <video src="/video/Main Clip.m4v" autoPlay loop="loop" muted></video>
      </motion.div>
    </div>
  );
}
